
  export default {
    components: {
      RichText: () => import('@/components/elements/RichText'),
    },
    props: {
      icon: {
        type: Object,
        required: false,
        default: () => {},
      },
    },
    computed: {
      linkColor() {
        return this.icon.color || '#2c2c2c';
      },
    },
  };
